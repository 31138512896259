// Hero Slider

.splide {
    &__pagination {
        position: absolute;
        z-index: 1;
        bottom: 13px;
        left: 50%;
        transform: translateX( -50% );
        padding: 0;

        @include media-breakpoint-up(xl) {
            bottom: 23px;
        }

        li {
            line-height: 1 !important;
            padding: 0 !important;

            &::before {
                content: none !important;
            }
        }

        &__page {
            display: inline-block;
            width: 26px;
            height: 2px;
            background: rgba($white, 0.5);
            margin: 3px;
            padding: 0;
            transition: all .2s linear;
            border: 0;

            @include media-breakpoint-up(xl) {
                width: 56px;
                height: 3px;
                margin: 3px 8px;
            }

            &.is-active {
                background: $white;
            }

            &:hover {
                cursor: pointer;
                background: rgba($white, 0.9);;
            }

            &:focus {
                outline: none;
            }
        }
    }
}
